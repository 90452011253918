<template>
  <header>
    <div class="container main_header">
      <a href="/" class="logo"><img src="../assets/img/logo.svg" alt=""></a>
      <ol class="header-links">
<!--        <li><a href="tel:+74954077657"><i class="icon icon-phone"></i><span>+7 (495) 407-76-57</span></a></li>-->
        <li v-if="isGrandRole">
          <div class="grand-menu">
            <select v-model="selectCompany" @change="setCompany">
              <option v-for="(company, index) in grandCompanies" :value="index" :key="index" v-html="company.name"></option>
            </select>
            <a href="/" @click="logOut()" title="Выход"><i class="icon icon-logout"></i></a>
          </div>
        </li>
        <li v-else><a href="/" @click="logOut()" title="Выход" class="grand-menu"><span v-html="companyName"></span><i class="icon icon-logout"></i></a></li>
      </ol>
    </div>
  </header>
  <nav class="navigation">
    <div class="container">
      <router-link class="nav-link" :to="{name: 'Marketplaces', params: {type: 'ozon'}}" active-class="active" v-if="ozonStoreId">
        Ozon
      </router-link>
      <router-link class="nav-link" :to="{name: 'Marketplaces', params: {type: 'yandex'}}" active-class="active" v-if="ymStoreId">
        Яндекс
      </router-link>
      <router-link class="nav-link" :to="{name: 'Waiting'}" active-class="active" v-if="isStandardCpn">
        Ожидает продажи
      </router-link>
      <router-link class="nav-link nwrap" :to="{name: 'Shipment'}" active-class="active" v-if="isStandardCpn">
        В пути
      </router-link>
      <router-link class="nav-link" :to="{name: 'Won'}" active-class="active">
        Продано
      </router-link>
      <router-link class="nav-link" :to="{name: 'Returns'}" active-class="active">
        Возвраты
      </router-link>
      <router-link class="nav-link" :to="{name: 'SelfDeal'}" active-class="active" v-if="isSelfDealCpn">
        Сделать заказ
      </router-link>
      <router-link class="nav-link" :to="{name: 'MyStore'}" active-class="active" v-if="storeId">
        Мой склад
      </router-link>
      <router-link class="nav-link" :to="{name: 'Movings'}" active-class="active" v-if="storeId">
        Отправления
      </router-link>
    </div>
  </nav>
</template>

<script>
import {actionTypes} from "@/store/modules/auth";
import {mapState} from "vuex";

export default {
  name: 'SSPHeader',
  props: {
    msg: String
  },
  data () {
    return {
      selectCompany: null,
    }
  },
  computed:{
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      isGrandRole: state => state.auth.isGrandRole,
      grandCompanies: state => state.auth.grandCompanies,
      companyName: state => state.auth.companyName,
      currentCompany: state => state.auth.currentCompany,
      storeId: state => state.auth.storeId,
      ozonStoreId: state => state.auth.ozonStoreId,
      ymStoreId: state => state.auth.ymStoreId,
      isSelfDealCpn: state => state.auth.isSelfDealCpn,
      isStandardCpn: state => state.auth.isStandardCpn
    }),
  },
  mounted() {
    this.selectCompany = this.currentCompany
  },
  watch: {
    currentCompany() {
      this.selectCompany = this.currentCompany
    }
  },
  methods: {
    async logOut (){
      await this.$store.dispatch(actionTypes.logout)
    },
    async setCompany (event){
      await this.$store.dispatch(actionTypes.setGrandCompany, {company_id: event.target.value, store_id: this.grandCompanies[event.target.value].store_id})
      window.location.reload();
    }
  }
}
</script>
