<template>
  <div class="create-deal-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div>
          <SSPLoader v-if="isLoading" />
          <div v-if="!isLoading" class="create_form">
            <div>
              <input type="text" class="search_article form-control" placeholder="Укажите актикул для заказа" v-model="article" @input="articleError = ''"/>
              <span class="error">{{ articleError }}</span>
            </div>
            <div class="express">
              <input type="checkbox" class="shin-checkbox" id="express" v-model="express">
              <label for="express">Экспресс заказ</label>
            </div>
            <button class="btn btn-submit" @click="createDeal()">Оформить заказ</button>
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/general";

export default {
  name: 'SSPSelfDeal',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader
  },
  data(){
    return {
      article: '',
      express: false,
      articleError: ''
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.general.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
    })
  },
  methods: {
    async createDeal() {
      var regex = /^[TtWw]\d{7}$/;

      // Проверка входной строки
      if (regex.test(this.article)) {
        await this.$store.dispatch(actionTypes.createSelfDeal, {article: this.article , express_deal: this.express})
        alert('Заказ создан, он появится в разделе "В пути" в ближайшее время')
        this.article = ''
        this.express = ''
      } else {
        this.articleError = 'Неверный формат артикула'
      }

    }
  }
}
</script>
