import {mainAxios} from "@/api/axios"

const getDeals = (Params) => {
    return mainAxios.post('/main/deals/', {...Params}).then(response => response.data.data)
}

const saleDeal = (Params) => {
    return mainAxios.post('/main/sale-deal/', {...Params}).then(response => response.data.data)
}

const returnDeal = (Params) => {
    return mainAxios.post('/main/return-deal/', {...Params}).then(response => response.data.data)
}

const refreshPay = (Params) => {
    return mainAxios.post('/main/refresh-pay/', {...Params}).then(response => response.data.data)
}

const myStore = (Params) => {
    return mainAxios.post('/main/my-store/').then(response => response.data.data)
}

const saleProduct = (Params) => {
    return mainAxios.post('/main/create-deal-from-my-store/', {...Params}).then(response => response.data.data)
}

const createSelfDeal = (Params) => {
    return mainAxios.post('/main/create-deal-on-article/', {...Params}).then(response => response.data.data)
}
export default {
    getDeals,
    saleDeal,
    returnDeal,
    refreshPay,
    myStore,
    saleProduct,
    createSelfDeal
}