import {mainAxios} from "@/api/axios"

// const checkAuth = async () => {
//     return await axios.post('/', {...config, method: "w_check_auth"}).then(response => response.data.result)
// }

const login = Credentials => {
    return mainAxios.post('/auth/login/', {...Credentials}).then(response => response.data)
}

const checkAuth = () => {
    return mainAxios.post('/auth/check/').then(response => response.data)
}

const setCompanyToGrandUser = (Params) => {
    return mainAxios.post('/auth/set-grand-company/', {...Params}).then(response => response.data)
}


export default {
    login,
    checkAuth,
    setCompanyToGrandUser
}