import generalApi from '@/api/general'
import authApi from "@/api/auth";

const state = {
  deals: [],
  products: [],
  isLoading: false,
  error: null
}

export const mutationTypes = {
  getDealsStart: '[general] Get Deals Start',
  getDealsSuccess: '[general] Get Deals Success',
  getDealsFailure: '[general] Get Deals Failure',
  loaderStart: '[general] loader start',
  loaderStop: '[general] loader stop',
  getProductsStart: '[general] Get Products Start',
  getProductsSuccess: '[general] Get Products Success',
  getProductsFailure: '[general] Get Products Failure',
}

export const actionTypes = {
  getDeals: '[general] getDeals',
  saleDeal: '[general] saleDeal',
  returnDeal: '[general] returnDeal',
  refreshPay: '[general] refreshPay',
  getProducts: '[general] myStore',
  saleProduct: '[general] saleProduct',
  createSelfDeal: '[general] createSelfDeal',
}

const mutations = {
  [mutationTypes.getDealsStart](state){
    state.deals = []
    state.isLoading = true
  },
  [mutationTypes.getDealsSuccess](state, payload){
    state.deals = payload.deals
    state.isLoading = false
  },
  [mutationTypes.getDealsFailure](state){
    state.deals = []
    state.isLoading = false
  },
  [mutationTypes.loaderStart](state){
    state.isLoading = true
  },
  [mutationTypes.loaderStop](state){
    state.isLoading = false
  },
  [mutationTypes.getProductsStart](state){
    state.products = []
    state.isLoading = true
  },
  [mutationTypes.getProductsSuccess](state, payload){
    state.products = payload.products
    state.isLoading = false
  },
  [mutationTypes.getProductsFailure](state){
    state.products = []
    state.isLoading = false
  }
}

const actions = {
  [actionTypes.getDeals](context, params) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getDealsStart)
      generalApi.getDeals(params)
          .then(response => {
            console.log(response)
            context.commit(mutationTypes.getDealsSuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getDealsFailure)
          })
    })
  },
  [actionTypes.saleDeal](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loaderStart)
      generalApi.saleDeal(params)
          .then((response) => {
            context.commit(mutationTypes.loaderStop)
            resolve(response)
          }).catch((err) => {
            console.log(err)
            context.commit(mutationTypes.loaderStop)
            reject()
          })
    })
  },
  [actionTypes.returnDeal](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loaderStart)
      generalApi.returnDeal(params)
          .then((response) => {
            context.commit(mutationTypes.loaderStop)
            resolve(response)
          }).catch((err) => {
            context.commit(mutationTypes.loaderStop)
            console.log(err)
            reject()
          })

    })
  },
  [actionTypes.refreshPay](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loaderStart)
      generalApi.refreshPay(params)
          .then((response) => {
            context.commit(mutationTypes.loaderStop)
            resolve(response)
          }).catch((err) => {
        console.log(err)
        context.commit(mutationTypes.loaderStop)
        reject()
      })
    })
  },
  [actionTypes.getProducts](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.getProductsStart)
      generalApi.myStore()
          .then(response => {
            console.log(response)
            context.commit(mutationTypes.getProductsSuccess, response)
            resolve(response)
          })
          .catch(() => {
            context.commit(mutationTypes.getProductsFailure)
          })
    })
  },
  [actionTypes.saleProduct](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loaderStart)
      generalApi.saleProduct(params)
          .then((response) => {
            context.commit(mutationTypes.loaderStop)
            resolve(response)
          }).catch((err) => {
        console.log(err)
        context.commit(mutationTypes.loaderStop)
        reject()
      })
    })
  },
  [actionTypes.createSelfDeal](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loaderStart)
      generalApi.createSelfDeal(params)
          .then((response) => {
            context.commit(mutationTypes.loaderStop)
            resolve(response)
          }).catch((err) => {
        console.log(err)
        context.commit(mutationTypes.loaderStop)
        reject()
      })
    })
  },
}

export default {
  state,
  mutations,
  actions
}
