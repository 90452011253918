import { createStore } from 'vuex'

import auth from "@/store/modules/auth";
import general from "@/store/modules/general";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    general
  }
})
