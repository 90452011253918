<template>
  <div class="deals-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div v-if="!isLoading" class="top_actions">
          <div><input type="text" class="search_article form-control" placeholder="Поиск" v-model="searchStr" /></div>
          <div>
            <button class="btn btn-print" @click="print"><i class="icon icon-print"></i></button>
            <button class="btn btn-refresh" @click="refresh()">Обновить</button>
          </div>
        </div>
        <div>
          <SSPLoader v-if="isLoading" />
          <div class="deals_list" v-if="Object.entries(sortResult)?.length">
            <div class="item" v-for="(item, index) in sortResult"
                 :key="index">
              <span class="index">#{{ item.id }} <small v-if="item.tk">ТК: {{ item.tk }}</small>&nbsp;<small v-if="item.city" class="city">Город получателя: {{ item.city }}</small></span>
              <span class="add_info" v-if="item.date_ship">
                <span>Дата доставки: <b>{{ displayDate(item.date_ship) }}</b></span>
                <template v-if="item.mark">
                  <a class="mark_link" :href="link" v-for="(link, i) in item.mark" :key="i">Маркировка <span v-if="i > 0"> {{ i + 1 }}</span></a>
                </template>
              </span>

              <div class="deal_info">
                <p v-for="(product, i) in item.products" :key="i">{{ product.title }} <span>{{ product.params }}</span> - {{ product.quantity }}шт.</p>
                <template v-if="item.dbs_ship">
                  <p v-if="item.dbs_address">Адрес клиента: {{ item.dbs_address }}</p>
                  <p v-else>Свяжитесь с представителем для уточнения адреса</p>
                  <p v-if="item.dbs_phone">Телефон клиента: <a :href="'tel:' + item.dbs_phone" class="href">{{ item.dbs_phone }}</a></p>
                  <p v-else>Свяжитесь с представителем для уточнения телефона</p>
                </template>
              </div>
              <div class="deal_cost">
                <span>Сумма: <b>{{ Math.ceil(item.cost) }} ₽</b></span>
              </div>
              <div class="deal_actions">
                <button class="btn btn-pay npad" @click="saleDeal(item.id)">Передать ТК</button>
                <button class="btn btn-submit" @click="returnDeal(item)">Возврат</button>
              </div>
            </div>
          </div>
          <div v-else-if="!isLoading" class="deals_not_found">
              Ничего не найдено
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/general";

export default {
  name: 'SSPMovings',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader
  },
  data(){
    return {
      searchStr: '',
      range: {
        start: new Date(2023, 9, 1),
        end: new Date(2023, 8, 1)
      }
    }
  },
  async beforeMount() {
    await this.$store.dispatch(actionTypes.getDeals, {type: 'movings'})
  },
  computed: {
    ...mapState({
      deals: state => state.general.deals,
      isLoading: state => state.general.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
    sortResult () {
      let arrayObj = Object.values(this.deals)
      arrayObj = arrayObj.sort(function(a,b) {
        if (a.pay > b.pay)
          return -1;
        if (a.pay < b.pay)
          return 1;
        return 0;
      });
      return Object.assign({}, arrayObj.filter((value)=>  JSON.stringify(value.products).toString().toLowerCase().includes(this.searchStr.toLowerCase())))
    }
  },
  methods: {
    async saleDeal(deal_id) {
      if (confirm("Подтверждение выдачи товара клиенту?")) {
        await this.$store.dispatch(actionTypes.saleDeal, {deal_id: deal_id})
        await this.refresh()
      }
    },
    async returnDeal(deal) {
      // this.showModal('Необходимо выбрать возвращаемые товары', deal.products)
      if (confirm("Клиент отказался от товара?")) {
        await this.$store.dispatch(actionTypes.returnDeal, {deal_id: deal.id, pay_status: deal.pay})
        await this.refresh()
      }
    },
    async refreshPay(deal) {
      if (confirm("Обновить ссылку на оплату для клиента? (Клиенту поступит новое СМС-сообщение)")) {
        await this.$store.dispatch(actionTypes.refreshPay, {deal_id: deal.id})
        await this.refresh()
      }
    },
    async refresh() {
      await this.$store.dispatch(actionTypes.getDeals, {type: 'movings'})
    },
    print() {
      window.print()
    },
    displayDate (date) {
      const dateObj = new Date(date)
      const month = dateObj.getUTCMonth() + 1
      const day = dateObj.getUTCDate()
      const year = dateObj.getUTCFullYear()
      const pMonth = month.toString().padStart(2,"0")
      const pDay = day.toString().padStart(2,"0")
      return `${pDay}/${pMonth}/${year}`
    }
  }
}
</script>