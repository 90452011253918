<template>
  <div class="deals-page main-wrapper my-store">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div v-if="!isLoading" class="top_actions">
          <div><input type="text" class="search_article form-control" placeholder="Поиск" v-model="searchStr" /></div>
          <div>
            <button class="btn btn-print" @click="print"><i class="icon icon-print"></i></button>
            <button class="btn btn-refresh" @click="refresh()">Обновить</button>
          </div>
        </div>
        <div>
          <SSPLoader v-if="isLoading" />
          <div class="deals_list" v-if="products?.length">
            <div class="item" v-for="(product, index) in products"
                 :key="index">
              <span class="index">#{{index+1}}</span>
              <div class="deal_info">
                <p>{{ product.title }} <span>{{ product.params }}</span> - {{ product.quantity }}шт.</p>
              </div>
              <div class="deal_cost">
                <span v-if="discount > 0">Сумма со скидкой: <b>{{ Math.ceil(product.cost * (100 - discount)/100) }} ₽</b></span>
                <span v-else>Сумма: <b>{{ product.cost }} ₽</b></span>
                <a :href="'https://shinsale.ru/' + product.slug" target="_blank">Товар на сайте Shinsale</a>
              </div>
              <div class="deal_actions">
                <button v-if="product.in_progress" class="btn btn-wait npad disabled">В обработке</button>
                <button v-else class="btn btn-pay npad" @click="saleProduct(product.code)">Продажа</button>
              </div>
            </div>
          </div>
          <div v-else-if="!isLoading" class="deals_not_found">
              Ничего не найдено
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/general";

export default {
  name: 'SSPMyStore',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader
  },
  data(){
    return {
      searchStr: '',
      range: {
        start: new Date(2023, 9, 1),
        end: new Date(2023, 8, 1)
      }
    }
  },
  async beforeMount() {
    await this.$store.dispatch(actionTypes.getProducts)
  },
  computed: {
    ...mapState({
      products: state => state.general.products,
      isLoading: state => state.general.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
      storeId: state => state.auth.storeId,
      discount: state => state.auth.discount,
    }),
    // sortResult () {
    //   let arrayObj = Object.values(this.deals)
    //   arrayObj = arrayObj.sort(function(a,b) {
    //     if (a.pay > b.pay)
    //       return -1;
    //     if (a.pay < b.pay)
    //       return 1;
    //     return 0;
    //   });
    //   return Object.assign({}, arrayObj.filter((value)=>  JSON.stringify(value.products).toString().toLowerCase().includes(this.searchStr.toLowerCase())))
    // }
  },
  methods: {
    async saleProduct(atricle) {
      if (confirm("Подтверждение продажи товара?")) {
        await this.$store.dispatch(actionTypes.saleProduct, {article: atricle})
        alert('Заказ создан, в ближайшее вермя он появится на вкладке "Ожидает продажи" с соответствующей пометкой')
        //await this.$router.push({ name: 'Waiting' })
        await this.refresh()
      }
    },
    async refresh() {
      await this.$store.dispatch(actionTypes.getProducts)
    },
    print() {
      window.print()
    }
  }
}
</script>