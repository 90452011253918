<template>
  <div class="deals-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div v-if="!isLoading" class="top_actions wrap">
          <div><input type="text" class="search_article form-control" placeholder="Поиск" v-model="searchStr" /></div>
          <div>
            <VueDatePicker
              class="datePicker"
              v-model="range"
              :max-date="maxDate"
              :format="format"
              range
              multi-calendars
              locale="ru"
              :format-locale="ru"
              :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
              :hide-navigation="['time' , 'minutes' , 'hours' , 'seconds']"
              auto-apply
              partial-flow
              :clearable="false"
              @update:model-value="refresh()">
            </VueDatePicker>
            <button class="btn btn-refresh" @click="refresh()">Обновить</button>
          </div>
        </div>
        <div>
          <SSPLoader v-if="isLoading" />
          <div class="deals_list" v-if="Object.entries(sortResult)?.length">
            <div class="item" v-for="(item, index) in sortResult"
                 :key="index">
              <span class="index">#{{ item.id }}</span>
              <div class="deal_info">
                <p v-for="(product, i) in item.products" :key="i">{{ product.title }} <span>{{ product.params }}</span></p>
              </div>
              <div class="deal_cost">
                <span>Сумма: <b>{{ Math.ceil(item.cost) }} ₽</b></span>
              </div>
              <div class="deal_actions">
                <button v-if="item.return == 1" class="btn btn-wait" disabled>Ожидает возврата</button>
                <button v-else class="btn btn-submit" @click="returnDeal(item.id)">Возврат</button>
              </div>
            </div>
          </div>
          <div v-else-if="!isLoading" class="deals_not_found">
            Ничего не найдено
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/general";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'SSPWon',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader,
    VueDatePicker
  },
  data(){
    return {
      searchStr: '',
      maxDate: new Date(),
      range: []
    }
  },
  async beforeMount() {
    const lastDay = new Date();
    const firstDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), 1);
    this.range = [firstDay, lastDay]
    await this.$store.dispatch(actionTypes.getDeals, {type: 'won', rangeDates: this.range})
  },
  computed: {
    ...mapState({
      deals: state => state.general.deals,
      isLoading: state => state.general.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
    sortResult () {
      let arrayObj = Object.values(this.deals)
      arrayObj = arrayObj.sort(function(a,b) {
        if (a.id > b.id)
          return -1;
        if (a.id < b.id)
          return 1;
        return 0;
      });
      return Object.assign({}, arrayObj.filter((value)=>  JSON.stringify(value.products).toString().toLowerCase().includes(this.searchStr.toLowerCase())))
    }
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(actionTypes.getDeals, {type: 'won', rangeDates: this.range})
    },
    async returnDeal(deal_id) {
      if (confirm("Клиент отказался от товара?")) {
        await this.$store.dispatch(actionTypes.returnDeal, {deal_id: deal_id})
        await this.refresh()
      }
    },
    format([date, lastDate]){
      const day = ("0" + date.getDate()).slice(-2)
      const month = ("0" + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      const lastDay = ("0" + lastDate.getDate()).slice(-2)
      const lastMonth = ("0" + (lastDate.getMonth() + 1)).slice(-2)
      const lastYear = lastDate.getFullYear()
      return `${day}.${month}.${year} - ${lastDay}.${lastMonth}.${lastYear}`
    }
  }
}
</script>

<style>
.dp__range_end, .dp__range_start, .dp__active_date {
  background: var(--orange);
}
.dp__today {
  border: 1px solid var(--orange);
}
</style>