<template>
  <div class="deals-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div v-if="!isLoading" class="top_actions">
          <div><input type="text" class="search_article form-control" placeholder="Поиск" v-model="searchStr" /></div>
          <button class="btn btn-refresh" @click="refresh()">Обновить</button>
        </div>
        <div>
          <SSPLoader v-if="isLoading" />
          <div class="deals_list" v-if="Object.entries(sortResult)?.length">
            <div class="item" v-for="(item, index) in sortResult"
                 :key="index">
              <span class="index">#{{ item.id }}</span>
              <div class="deal_info">
                <p v-for="(product, i) in item.products" :key="i">{{ product.title }} <span>{{ product.params }}</span></p>
              </div>
              <div class="deal_cost">
                <span>Сумма: <b>{{ Math.ceil(item.cost) }} ₽</b></span>
              </div>
              <div class="deal_actions">
                <button class="btn btn-wait npad" disabled>{{ item.stage }}</button>
              </div>
            </div>
          </div>
          <div v-else-if="!isLoading" class="deals_not_found">
            Ничего не найдено
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/general";

export default {
  name: 'SSPReturns',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader
  },
  data(){
    return {
      searchStr: ''
    }
  },
  async beforeMount() {
    await this.$store.dispatch(actionTypes.getDeals, {type: 'returns'})
  },
  computed: {
    ...mapState({
      deals: state => state.general.deals,
      isLoading: state => state.general.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
    }),
    sortResult () {
      return Object.fromEntries(Object.entries(this.deals).filter(([key, value])=>  JSON.stringify(value.products).toString().toLowerCase().includes(this.searchStr.toLowerCase())))
    }
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(actionTypes.getDeals, {type: 'returns'})
    }
  }
}
</script>
