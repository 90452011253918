<template>
  <router-view/>
</template>

<script>
import {actionTypes} from "@/store/modules/auth";
import {mapState} from 'vuex'

export default {
  name: 'McvApp',
  components: {

  },
  computed:{
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      isLoading: state => state.auth.isLoading,
      ozonStoreId: state => state.auth.ozonStoreId,
      ymStoreId: state => state.auth.ymStoreId,
      isStandardCpn: state => state.auth.isStandardCpn
    }),
  },
  async beforeMount() {
    await this.$store.dispatch(actionTypes.checkAuth)
  },
  watch:{
    isLoggedIn(){
      if (!this.isLoading) {
        if (!this.isLoggedIn) this.$router.push({name: 'Login'})
        else if (this.isStandardCpn) this.$router.push({name: 'Waiting'})
        else if (this.ozonStoreId) this.$router.push({name: 'Marketplaces', params: {type: 'ozon'}})
        else if (this.ymStoreId) this.$router.push({name: 'Marketplaces', params: {type: 'yandex'}})
        else this.$router.push({name: 'Won'})
      }
    }
  }
}
</script>

<style src="../public/css/main.css?2">

</style>


