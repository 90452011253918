<template>
  <div class="login-page">

    <form @submit.prevent="onSubmit">
      <SSPLoader v-if="isSubmitting" />
      <a href="/" class="logo-link"><img src="@/assets/img/logo.svg" alt=""></a>
        <input
            type="text"
            v-mask="'+7 (###) ###-##-##'"
            class="form-control"
            placeholder="+7 (999) 123-45-67"
            v-model="phone"
        />
        <input
            type="password"
            class="form-control"
            placeholder="Пароль"
            v-model="password"
        />
      <button class="btn btn-submit">Войти</button>
    </form>
  </div>
</template>


<script>
import {mapState} from "vuex";
import {actionTypes} from "@/store/modules/auth";
import SSPLoader from "@/components/Loader.vue";
import {mask} from 'vue-the-mask'

export default {
  name: 'SSPLogin',
  directives: {
    mask
  },
  components: {
    SSPLoader
  },
  data() {
    return {
      phone: "",
      password: ""
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state.auth.isSubmitting,
      isLoggedIn: state => state.auth.isLoggedIn,
    })
  },
  watch: {
    isLoggedIn(){
      if(this.isLoggedIn){
        this.$router.push({name: 'Waiting'})
      }
    }
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch(actionTypes.login, {phone: this.phone.replace(/\D/g,''), password: this.password})
      await this.$store.dispatch(actionTypes.checkAuth)
    }
  }
}
</script>
