import axios from "axios"
import {getItem} from "@/helpers/persistanceStorage"



const authAxios = axios.create({
    baseURL: process.env.VUE_APP_API,
});

const mainAxios = axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: {
        "Content-Type": "application/json",
        "Accept": "*/*",
    }
});

mainAxios.interceptors.request.use(config => {
    const token = getItem('accessToken')
    const authorizationToken = token ? `Bearer ${token}`: ''
    config.headers.Authorization = authorizationToken
    return config
})

export {
    authAxios,
    mainAxios,
};
