import authApi from '@/api/auth'
import {getItem, setItem} from '@/helpers/persistanceStorage'
import generalApi from "@/api/general";

const state = {
  isSubmitting: true, // todo false
  isLoading: false,
  validationErrors: null,
  isLoggedIn: null,
  isGrandRole: false,
  grandCompanies: [],
  companyName: '',
  currentCompany: null,
  storeId: null,
  ozonStoreId: null,
  ymStoreId: null,
  isSelfDealCpn: false,
  isStandardCpn: false,
  discount: 0
}

export const mutationTypes = {
  checkAuthStart: '[auth] Check Auth Start',
  checkAuthSuccess: '[auth] Check Auth Success',
  checkAuthFailure: '[auth] Check Auth Failure',

  loginStart: '[auth] Login Start',
  loginSuccess: '[auth] Login Success',
  loginFailure: '[auth] Login Failure',

  setCurrentCompany: '[auth] Set Current Company',

  logout: '[auth] Logout',
}

export const actionTypes = {
  checkAuth: '[auth] checkAuth',
  login: '[auth] login',
  logout: '[auth] logout',
  getCurrentCompany: '[auth] Get Current Company',
  setCurrentCompany: '[auth] Set Current Company',
  setGrandCompany: '[general] set grand company',
}

export const getterTypes = {
  isLoggedIn: '[auth] isLoggedIn',
}

const getters = {
  [getterTypes.isLoggedIn]: state => {
    return Boolean(state.isLoggedIn)
  }
}

const mutations = {
  [mutationTypes.checkAuthStart](state){
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.checkAuthSuccess](state, payload){
    state.isSubmitting = false
    state.isLoggedIn = Boolean(payload.success)
    state.currentCompany = payload.user.company_id
    state.companyName = payload.user.company_name
    state.storeId = payload.user.store_id
    state.ozonStoreId = payload.user.ozon_store_id ?? 0
    state.ymStoreId = payload.user.ym_store_id ?? 0
    state.showPhones = payload.user.show_client_phones ?? false
    state.isStandardCpn = payload.user.is_standard_cpn ?? false
    state.isSelfDealCpn = payload.user.is_self_deal_cpn ?? false
    state.discount = payload.user.discount ?? 0
    if(payload.user.grand_role) {
      state.isGrandRole = true
      state.grandCompanies = payload.companies
    }
  },
  [mutationTypes.checkAuthFailure](state){
    state.isSubmitting = false
  },

  [mutationTypes.loginStart](state){
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.loginSuccess](state){
    state.isSubmitting = false
    state.isLoggedIn = true
  },
  [mutationTypes.loginFailure](state){
    state.isSubmitting = false
  },
  [mutationTypes.setCurrentCompany](state, payload){
    state.currentCompany = payload
  },

  [mutationTypes.logout](state){
    state.isLoggedIn = false
  }
}

const actions = {
  // todo проверка токена авторизации из localstorage
  [actionTypes.checkAuth](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.checkAuthStart)
      if(typeof getItem('accessToken') === 'undefined') {
        context.commit(mutationTypes.checkAuthFailure)
        reject()
      } else {
        authApi.checkAuth()
            .then(response => {
              context.commit(mutationTypes.checkAuthSuccess, response)
              resolve(response)
            })
            .catch(() => {
              context.commit(mutationTypes.checkAuthFailure)
            })
      }
    })
  },

  [actionTypes.login](context, credentials) {
    return new Promise(resolve => {
      context.commit(mutationTypes.loginStart)
      authApi.login(credentials)
        .then(response => {
          if(response.success === 1) {
            context.commit(mutationTypes.loginSuccess)
            setItem('accessToken', response.token)
          }
          if(response.success === 0) throw response;
          resolve(response)
        })
        .catch(() => {
          context.commit(mutationTypes.loginFailure)
        })
    })
  },

  [actionTypes.getCurrentCompany](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.setCurrentCompany, getItem('currentCompany'))
      resolve()
    })
  },

  [actionTypes.setCurrentCompany](context, company_id) {
    return new Promise(resolve => {
      setItem('currentCompany', company_id)
      context.commit(mutationTypes.setCurrentCompany, company_id)
      resolve()
    })
  },

  [actionTypes.setGrandCompany](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loginStart)
      authApi.setCompanyToGrandUser(params)
          .then((response) => {
            resolve(response)
          }).catch((err) => {
        console.log(err)
        reject()
      })

    })
  },

  [actionTypes.logout](context) {
    return new Promise(resolve => {
      context.commit(mutationTypes.logout)
      localStorage.removeItem('accessToken');
      resolve()
    })
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
