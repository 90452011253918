import { createRouter, createWebHistory } from 'vue-router'
import SSPWaiting from '@/views/Waiting.vue'
import SSPShipment from '@/views/Shipment.vue'
import SSPReturns from '@/views/Returns.vue'
import SSPWon from '@/views/Won.vue'
import SSPLogin from '@/views/Login.vue'
import SSPMyStore from '@/views/MyStore.vue'
import SSPSelfDeal from '@/views/SelfDeal.vue'
import SSPMarketplaces from '@/views/Marketplaces.vue'
import SSPMovings from '@/views/Movings.vue'

const routes = [
  {
    path: '/',
    name: 'Waiting',
    component: SSPWaiting,
  },
  {
    path: '/shipment',
    name: 'Shipment',
    component: SSPShipment,
  },
  {
    path: '/won',
    name: 'Won',
    component: SSPWon,
  },
  {
    path: '/returns',
    name: 'Returns',
    component: SSPReturns,
  },
  {
    path: '/login',
    name: 'Login',
    component: SSPLogin
  },
  {
    path: '/my-store',
    name: 'MyStore',
    component: SSPMyStore
  },
  {
    path: '/self-deal',
    name: 'SelfDeal',
    component: SSPSelfDeal
  },
  {
    path: '/marketplaces/:type',
    name: 'Marketplaces',
    component: SSPMarketplaces
  },
  {
    path: '/movings',
    name: 'Movings',
    component: SSPMovings
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
